import React from "react"
import { Landing } from "src/components"
import Layout from "src/layout"

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Landing />
    </Layout>
  )
}

export default IndexPage
